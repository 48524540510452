<i18n>
{
  "en": {
		"selectYear": "Select year",
		"all": "All",
		"noItemsMessage": "No magazines to show.",
		"noSubscriptionMessage": "The magazine archive is only visible to subscribers to the Paperi ja puu magazine."
	},
  "fi": {
		"selectYear": "Valitse vuosi",
		"all": "Kaikki",
		"noItemsMessage": "Jäsenlehtiä ei löytynyt.",
		"noSubscriptionMessage": "Lehtiarkisto on näkyvillä vain Paperi ja puu -lehden tilaajille."
	}
}
</i18n>

<template>
	<v-container
		class="container--narrow"
	>
		<Spinner v-if="loading === true" />
		<template v-else>
			<template v-if="user._show_magazines === true">
				<v-card class="mb-6">
					<v-card-text>
						<v-select
							v-model="selectedYear"
							:items="years"
							:label="$i18n.t('selectYear')"
							item-text="label"
							item-value="value"
							prepend-icon="mdi-calendar"
							hide-details
						/>
					</v-card-text>
				</v-card>
				<v-row
					v-if="filteredItems.length"
				>
					<v-col
						v-for="item in filteredItems"
						:key="item.id"
						cols="6"
						sm="4"
					>
						<v-card
							:href="item.file_pdf.url"
							target="_blank"
						>
							<v-img
								v-if="item.image"
								:src="item.image.url"
								aspect-ratio="0.8"
							/>
							<v-card-text class="text-center">
								{{ item.title }}
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
				<v-alert
					v-else
					type="info"
				>
					{{ $i18n.t('noItemsMessage') }}
				</v-alert>
			</template>
			<v-alert
				v-else
				type="info"
			>
				{{ $i18n.t('noSubscriptionMessage') }}
			</v-alert>
		</template>
	</v-container>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'MagazineContainer',
	data: () => ({
		loading: true,
		selectedYear: null,
	}),
	computed: {
		...mapState({
			user: state => state.user,
			items: state => state.magazines.items,
		}),
		filteredItems () {
			if (!this.selectedYear) return this.items

			return this.items.filter(item => item.issue_year === this.selectedYear)
		},
		years () {
			return [
				{
					value: null,
					label: this.$i18n.t('all'),
				},
				...this.$store.state.magazines.years || [],
			]
		},
	},
	async mounted () {
		await this.$api.Magazines.doRequest()

		this.loading = false
	},
}
</script>
