<template>
	<div ref="wrap">
		<div
			v-for="(item, index) in items"
			:key="item.id"
			:class="[
				index > 0 ? 'pt-6' : null,
				index < items.length -1 ? 'pb-6' : null,
			]"
		>
			<div
				v-if="item.type === 'text_content_block' && item.content"
				class="rte"
				v-html="item.content"
			/>
			<template v-else-if="item.type === 'image_block' && item.image">
				<v-img
					:src="item.image.url"
					:alt="item.image.alt"
				/>
			</template>
			<template v-else-if="item.type === 'video_block' && item.video_link">
				<CookieContent type="marketing">
					<div v-html="item.video_link" />
				</CookieContent>
			</template>
			<template v-else-if="item.type === 'files_block' && item.files.length">
				<FilterableList
					:items="item.files"
					:enable-search="false"
					:multiline="true"
					title-src="list_title"
					summary-src="list_summary"
					redirect-url-src="url"
				/>
			</template>
			<template v-else-if="item.type === 'gallery_block' && item.images.length">
				<v-row
					align-content="start"
					align="start"
				>
					<v-col
						v-for="(image, imageIndex) of item.images"
						:key="imageIndex"
						cols="4"
						sm="3"
					>
						<v-btn
							block
							height="auto"
							class="pa-0"
							@click="openImage(image, item.images)"
						>
							<v-img
								aspect-ratio="1"
								:src="image.url"
							/>
						</v-btn>
					</v-col>
				</v-row>
			</template>
		</div>

		<v-dialog
			v-model="imageDialogOpen"
			:max-width="imageDialogWidth"
		>
			<v-card
				v-if="selectedImage && selectedImage.url"
			>
				<v-card-text
					class="pa-0"
					@click="imageDialogOpen = false"
				>
					<v-img
						:src="selectedImage.url"
						width="100%"
						:aspect-ratio="imageAspectRatio"
					/>
				</v-card-text>
				<template v-if="selectedImage.description">
					<v-divider />
					<v-card-text v-if="selectedImage.description">
						{{ selectedImage.description }}
					</v-card-text>
				</template>
				<v-card-actions
					class="d-flex justify-center"
					v-if="imagesArray && imagesArray.length > 1"
				>
					<v-btn
						color="secondary"
						@click="prevImage()"
					>
						<v-icon>mdi-chevron-left</v-icon>
					</v-btn>
					<v-btn
						color="secondary"
						@click="nextImage()"
					>
						<v-icon>mdi-chevron-right</v-icon>
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>

export default {
	name: 'ContentBlocks',
	props: {
		items: {
			type: [Array],
			required: false,
			default () {
				return []
			},
		},
	},
	data: () => ({
		imageDialogOpen: false,
		selectedImage: null,
		imagesArray: [],
	}),
	computed: {
		imageDialogWidth () {
			if (!this.selectedImage) return 640

			return this.selectedImage.width <= 640 ? this.selectedImage.width : 640
		},
		imageAspectRatio () {
			if (!this.selectedImage) return 1

			return this.selectedImage.width / this.selectedImage.height
		},
	},
	mounted () {
		this.init()
	},
	updated () {
		this.init()
	},
	methods: {
		init () {
			if (typeof this.$refs.wrap == 'undefined') return

			// Force links to open in new window
			const links = this.$refs.wrap.querySelectorAll('.rte a')

			for (const item of links) {
				const href = item.getAttribute('href')

				if (!href) continue
				if (href.startsWith('tel:') || href.startsWith('mailto:') || href.startsWith('#')) continue

				item.setAttribute('target', '_blank')
			}
		},
		openImage (item, imagesArray) {
			this.selectedImage = item
			this.imagesArray = imagesArray
			this.imageIndex = imagesArray.indexOf(item)

			this.$nextTick(() => {
				this.imageDialogOpen = true
			})
		},
		prevImage () {
			const prevIndex = (this.imageIndex - 1 + this.imagesArray.length) % this.imagesArray.length
			this.selectedImage = this.imagesArray[prevIndex]
			this.imageIndex = prevIndex
		},
		nextImage () {
			const nextIndex = (this.imageIndex + 1) % this.imagesArray.length
			this.selectedImage = this.imagesArray[nextIndex]
			this.imageIndex = nextIndex
		},
	},
}
</script>
