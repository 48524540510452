<i18n>
{
  "en": {
		"noItemsText": "No pages to show."
	},
  "fi": {
		"noItemsText": "Sivuja ei löytynyt."
	}
}
</i18n>

<template>
	<div>
		<v-container class="container--narrow">
			<Spinner v-if="loading === true" />
			<template v-else-if="currentItem">
				<template v-if="currentItem.template === 'BasicPage'">
					<v-container
						v-if="currentItem.hero_image"
						fluid
						pa-0
					>
						<v-img
							:src="currentItem.hero_image.url"
							:alt="currentItem.hero_image.alt"
							height="200"
						/>
					</v-container>
					<v-container class="container--narrow pt-6">
						<p
							v-if="currentItem.summary"
							class="font-weight-medium"
						>
							{{ currentItem.summary }}
						</p>
						<ContentBlocks :items="currentItem.content_blocks" />
					</v-container>
				</template>
				<FilterableList
					v-else
					:items="currentItem.children"
					icon-src="_icon"
					:no-items-text="this.$i18n.t('noItemsText')"
					@itemClick="itemClick"
				/>
			</template>
		</v-container>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'PageContainer',
	data: () => ({
		loading: true,
		currentItem: null,
		items: [],
	}),
	computed: {
		...mapState({
			pages: state => state.pages,
		}),
	},
	watch: {
		'$route.path': {
			handler () {
				this.currentItem = this.findCurrentItem()
			},
		},
		currentItem (val) {
			this.$root.$emit('setHeadline', val.title)
		},
	},
	async mounted () {
		await this.$api.Pages.doRequest()

		this.$nextTick(() => {
			this.currentItem = this.findCurrentItem()
			this.loading = false
		})
	},
	methods: {
		// Find current item to render
		findCurrentItem () {
			let match

			if (this.$route.params && this.$route.params.pathMatch) {
				const slugs = this.$route.params.pathMatch
					.replace(/\/+$/, '')
					.split('/')
					.filter(item => !!item)
				let searchFrom = this.pages

				// Loop all slugs and make sure that every slug matches to an item
				for (const slug of slugs) {
					match = searchFrom.find(item => item.name == slug)

					if (typeof match !== 'object') {
						this.$router.replace({ name: 'error404' })
						return null
					}

					// Continue searching from the children of the matched item
					searchFrom = match.children || []
				}
			} else {
				match = {
					children: this.pages,
				}
			}

			// Remap match children
			if (match && match.children) {
				match.children = match.children.map(item => {
					// Add icon
					if (item.template === 'BasicPageFolder') {
						item._icon = 'mdi-folder'
					} else if (item.template === 'BasicPage') {
						if (item.attachment) {
							item._icon = 'mdi-attachment'
						} else {
							item._icon = 'mdi-file-document-outline'
						}
					}

					// Set redirect url
					if (item.attachment) {
						item.redirect_url = item.attachment.url
					}

					return item
				})
			}

			return match
		},
		itemClick (item) {
			this.$router.push({ path: this.$route.path + '/' + item.name })
		},
	},
}
</script>
