<i18n>
{
  "en": {
		"list": {
			"noSearchResults": "There are currently no members to show."
		},
		"details": {
			"birthdate": "Birthdate",
			"birthdayInformation": "Additional information",
			"degree": "Degree",
			"profile": "Profile",
			"viewProfile": "View profile",
			"honoraryTitle": "Position",
			"city": "City"
		}
	},
  "fi": {
		"list": {
			"noSearchResults": "Tällä hetkellä ei ole näytettäviä tietoja."
		},
		"details": {
			"birthdate": "Syntymäaika",
			"birthdayInformation": "Lisätiedot",
			"degree": "Oppiarvo",
			"profile": "Profiili",
			"viewProfile": "Näytä profiili",
			"honoraryTitle": "Titteli",
			"city": "Paikkakunta"
		}
	}
}
</i18n>

<template>
	<v-container class="container--narrow">
		<Spinner v-if="ready === false" />
		<template v-else>
			<div class="my-6">
				<Spinner v-if="workerLoading === true" />
				<FilterableList
					v-else
					:items="itemsVisible"
					:enable-search="false"
					title-src="title"
					subtitle-src="_list_group"
					icon-src="_list_icon"
					avatar-src="_list_avatar"
					:no-items-text="$i18n.t('list.noSearchResults')"
					:no-results-text="$i18n.t('list.noSearchResults')"
					@itemClick="onItemClick"
				>
					<template #avatar="{item}">
						<v-img
							alt=""
							:src="item._list_avatar"
						/>
					</template>
				</FilterableList>
			</div>

			<!-- Scroll to top button -->
			<v-fab-transition>
				<v-btn
					v-show="showScrollTopButton === true && memberDialogOpen === false"
					fab
					dark
					right
					fixed
					color="primary"
					class="scrollTopButton"
					@click="scrollTop"
				>
					<v-icon>mdi-arrow-up</v-icon>
				</v-btn>
			</v-fab-transition>

			<!-- Details dialog -->
			<v-dialog
				v-model="memberDialogOpen"
				max-width="400"
				scrollable
			>
				<v-card v-if="selectedMember">
					<v-card-title>
						<span>{{ selectedMember.title }}</span>
						<v-spacer />
						<v-btn
							icon
							@click="memberDialogOpen = false"
						>
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-card-title>
					<v-card-text
						ref="modalText"
						class="pa-0"
					>
						<v-img
							v-if="selectedMember._profile_pic_urls && selectedMember._profile_pic_urls['500x600']"
							:src="selectedMember._profile_pic_urls['500x600']"
							aspect-ratio="0.8"
						/>
						<FilterableList
							v-if="selectedMember._details"
							class="pa-3"
							:items="selectedMember._details"
							subtitle-src="label"
							title-src="value"
							icon-src="icon"
							:enable-click="(false)"
							:enable-search="(false)"
							:multiline="true"
						/>
					</v-card-text>
				</v-card>
			</v-dialog>
		</template>
	</v-container>
</template>

<script>

import { baseUrl } from '@/plugins/api'
import { mapState } from 'vuex'

export default {
	name: 'Birthdays',
	data: () => ({
		baseUrl: baseUrl, // Backend base url
		ready: false, // Ready to be rendered?
		workerLoading: true, // Is web worker loading?
		options: {}, // Options from the register
		items: [], // All items
		itemsFiltered: [], // Filtered results, split in chunks/pages
		itemsVisible: [], // Items to be rendered
		terms: {
			name: null,
			company: null,
			academy: null,
		}, // Search terms
		pageSize: 50, // How many results per page?
		nextPage: null, // Next page number
		scrollTicking: false, // Is the scroll ticking?
		termsDebounceTimeout: null, // Timeout used to debounce terms change
		memberDialogOpen: false, // Is member dialog open?
		selectedMember: null,
		showScrollTopButton: false,
	}),
	computed: {
		placeholderSrc () {
			return require('@/assets/person-placeholder.jpg')
		},
		...mapState([
			'user',
		]),
	},
	watch: {
		// When items are changed, filter them
		items: {
			deep: true,
			handler (val) {
				this.filterItems(val, this.terms, this.pageSize)
			},
		},

		// When search terms are changed, filter items
		terms: {
			deep: true,
			handler (val) {
				if (this.ready === false) return

				clearTimeout(this.termsDebounceTimeout)

				if (val.name && val.name.length) {
					console.log(this.normalizeStr(val.name))
				}

				this.termsDebounceTimeout = setTimeout(() => {
					this.filterItems(this.items, {
						...val,
						name: this.normalizeStr(val.name),
					}, this.pageSize)
				}, 300)
			},
		},

		itemsFiltered: {
			deep: true,
			handler () {
				this.itemsVisible = []
				this.nextPage = 0
				this.insertNextPage()
			},
		},
	},
	async mounted () {
		this.ready = false

		// @todo remove this
		if (!this.user._show_new_member_lists) {
			this.$router.push({ name: 'membershipCard' })
			return
		}

		const request = await this.$api.Birthdays.doRequest()

		if (request.success === true && request.result) {
			this.items = request.result.body.items || []
			this.options = request.result.body.options || {}

			this.$nextTick(async () => {
				this.ready = true

				// Add event listener for scrolling
				window.addEventListener('scroll', this.onScroll)

				// Depending on pagination chunk size, we might already reached the bottom of the page,
				// so we evaluate scroll just in case.
				this.onScroll()
			})

		}
	},

	beforeDestroy () {
		window.removeEventListener('scroll', this.onScroll)
	},

	methods: {
		normalizeStr (str = '') {
			if (!str || !str.length) return ''

			return str.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '')
		},

		// Filter items and split to chunks
		filterItems (items = [], terms = {}, pageSize) {
			this.workerLoading = true

			this.$worker.run(function (items, terms, pageSize) {
				if (!items.length) return []

				// We need to duplicate this function here, since worker has no access outside of it's scope.
				function normalizeStr (str) {
					if (!str || !str.length) return ''

					return str.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '')
				}

				let results

				if (terms && Object.keys(terms).length) {
					let nameRegex = new RegExp(terms.name, 'i')
					let companyRegex = new RegExp(terms.company, 'i')

					results = items.filter(item => {
						if (
							terms.name &&
							terms.name.length &&
							(
								!nameRegex.test(normalizeStr(item.title)) &&
								!nameRegex.test(normalizeStr([item.firstname, item.lastname].join(' ')))
							)
						) return false
						if (terms.company && terms.company.length && !companyRegex.test(item.c_company)) return false
						if (terms.academy && item.c_degree_institute_choose != terms.academy) return false
						if (terms.membershipType && item.membership_type != terms.membershipType) return false

						return true
					})
				} else {
					results = items
				}

				// Split into pages/chunks
				return Array.from({ length: Math.ceil(results.length / pageSize) }, (v, i) =>
					results.slice(i * pageSize, i * pageSize + pageSize)
				)
			}, [items, terms, pageSize]).then(result => {
				this.itemsFiltered = result
			}).catch(error => {
				console.log(error)

				this.itemsFiltered = []
			}).then(() => {
				this.workerLoading = false
			})
		},

		insertNextPage () {
			const page = this.itemsFiltered[this.nextPage]

			if (typeof page == 'undefined') return

			this.itemsVisible = [
				...this.itemsVisible,
				...page,
			]

			if (typeof this.itemsFiltered[this.nextPage +1] != 'undefined') {
				this.nextPage++
			} else {
				this.nextPage = null
			}
		},

		// When viewport is scrolled
		onScroll () {
			if (
				this.scrollTicking === true ||
        !this.nextPage
			) return

			this.scrollTicking = true

			window.requestAnimationFrame(() => {
				// If scroll position is near the bottom of the page, insert next page
				if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight * 0.8)) {
					this.insertNextPage()
				}

				this.showScrollTopButton = (window.scrollY > 430)

				this.scrollTicking = false
			})
		},

		onItemClick (item) {
			this.selectedMember = item

			// Parse detail list selected member
			this.selectedMember._details = [
				{
					icon: 'mdi-calendar',
					label: this.$i18n.t('details.birthdate'),
					value: this.selectedMember.birthdate || null,
				},
				{
					icon: 'mdi-school',
					label: this.$i18n.t('details.degree'),
					value: (() => {
						let out = []

						if (this.selectedMember.c_degree_choose) {
							const degree = this.options.c_degree_choose.find(item => item.id == this.selectedMember.c_degree_choose)

							if (degree) {
								if (degree.name == 'muu-oppiarvo' && this.selectedMember.c_degree_1) {
									out.push(this.selectedMember.c_degree_1)
								} else {
									out.push(degree.title)
								}
							}
						}

						out.push(this.selectedMember.c_degree_additional)

						return out.filter(item => item).join('<br>')
					})(),
				},
				{
					icon: 'mdi-medal',
					label: this.$i18n.t('details.honoraryTitle'),
					value: this.selectedMember._work_status == 'active' ? this.selectedMember.c_honorary_title : null,
				},
				{
					icon: 'mdi-information',
					label: this.$i18n.t('details.birthdayInformation'),
					value: this.selectedMember.c_birthday_information || null,
				},
				{
					icon: 'mdi-map-marker',
					label: this.$i18n.t('details.city'),
					value: (() => {
						let out = []

						if (this.selectedMember.city) {
							out.push(this.selectedMember.city)
						}

						if (this.selectedMember.country) {
							const country = this.options.country.find(item => item.id == this.selectedMember.country)

							if (country) {
								out.push(country.title)
							}
						}

						return out.filter(item => item).join(', ')
					})(),
				},
				{
					icon: (() => {
						let icon = 'mdi-link'

						if (this.selectedMember.c_profile) {
							const url = new URL(this.selectedMember.c_profile)
							if (url.hostname == 'www.linkedin.com' || url.hostname == 'linkedin.com') {
								icon = 'mdi-linkedin'
								// icon = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77Z"/></svg>'
							} else if (url.hostmame == 'www.facebook.com' || url.hostname == 'facebook.com') {
								icon = 'mdi-facebook'
								// icon = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 2.04c-5.5 0-10 4.49-10 10.02c0 5 3.66 9.15 8.44 9.9v-7H7.9v-2.9h2.54V9.85c0-2.51 1.49-3.89 3.78-3.89c1.09 0 2.23.19 2.23.19v2.47h-1.26c-1.24 0-1.63.77-1.63 1.56v1.88h2.78l-.45 2.9h-2.33v7a10 10 0 0 0 8.44-9.9c0-5.53-4.5-10.02-10-10.02Z"/></svg>'
							} else if (url.hostname == 'www.twitter.com' || url.hostname == 'twitter.com') {
								icon = 'mdi-twitter'
								// icon = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M22.46 6c-.77.35-1.6.58-2.46.69c.88-.53 1.56-1.37 1.88-2.38c-.83.5-1.75.85-2.72 1.05C18.37 4.5 17.26 4 16 4c-2.35 0-4.27 1.92-4.27 4.29c0 .34.04.67.11.98C8.28 9.09 5.11 7.38 3 4.79c-.37.63-.58 1.37-.58 2.15c0 1.49.75 2.81 1.91 3.56c-.71 0-1.37-.2-1.95-.5v.03c0 2.08 1.48 3.82 3.44 4.21a4.22 4.22 0 0 1-1.93.07a4.28 4.28 0 0 0 4 2.98a8.521 8.521 0 0 1-5.33 1.84c-.34 0-.68-.02-1.02-.06C3.44 20.29 5.7 21 8.12 21C16 21 20.33 14.46 20.33 8.79c0-.19 0-.37-.01-.56c.84-.6 1.56-1.36 2.14-2.23Z"/></svg>'
							}
						}

						return icon
					})(),
					label: this.$i18n.t('details.profile'),
					value: this.selectedMember.c_profile ? '<a href="' + this.selectedMember.c_profile + '" target="_blank">' + this.$i18n.t('details.viewProfile') + '</a>' : null,
				},
			].filter(item => item.value)

			// Open dialog and scroll to top
			this.memberDialogOpen = true

			this.$nextTick(() => {
				this.$refs.modalText.scrollTop = 0
			})
		},

		scrollTop () {
			this.$vuetify.goTo(0)
		},
	},
}

</script>

<style lang="scss" scoped>

.scrollTopButton {
	bottom: 70px;
}

</style>
