<i18n>
{
  "en": {
		"form": {
			"title": "Search for member",
			"noSearchResults": "Your search didn't match any members."
		},
		"fields": {
			"name": {
				"label": "Search by name"
			},
			"company": {
				"label": "Search by company"
			},
			"academy": {
				"label": "Search by academy"
			},
			"membershipType": {
				"label": "Search by membership type"
			}
		},
		"details": {
			"birthdate": "Birthdate",
			"honoraryTitle": "Position",
			"profile": "Profile",
			"viewProfile": "View profile",
			"educationDetails": "Education details",
			"jobInformation": "Job information",
			"emailAddress": "E-mail address",
			"mobile": "Mobile phone number",
			"workPhone": "Work phone number",
			"homeAddress": "Home address",
			"joinDate": "Join date"
		},
		"workStatuses": {
			"student": "Student",
			"retired": "Retired"
		}
	},
  "fi": {
		"form": {
			"title": "Etsi jäsentä",
			"noSearchResults": "Haullasi ei löytynyt jäseniä."
		},
		"fields": {
			"name": {
				"label": "Hae henkilön nimellä"
			},
			"company": {
				"label": "Hae työpaikan nimellä"
			},
			"academy": {
				"label": "Hae oppilaitoksella"
			},
			"membershipType": {
				"label": "Hae jäsenlajilla"
			}
		},
		"details": {
			"birthdate": "Syntymäaika",
			"honoraryTitle": "Titteli",
			"educationDetails": "Opintotiedot",
			"jobInformation": "Työpaikan tiedot",
			"emailAddress": "Sähköpostiosoite",
			"mobile": "Matkapuhelinnumero",
			"workPhone": "Työpuhelinnumero",
			"homeAddress": "Kotiosoite",
			"joinDate": "Liittymispäivä"
		},
		"workStatuses": {
			"student": "Opiskelija",
			"retired": "Eläkeläinen"
		}
	}
}
</i18n>

<template>
	<v-container class="container--narrow">
		<Spinner v-if="ready === false" />
		<template v-else>
			<v-card>
				<v-card-title>
					{{ $i18n.t('form.title') }}
				</v-card-title>
				<v-divider />
				<v-card-text>
					<v-form>
						<v-text-field
							v-model="terms.name"
							:label="$i18n.t('fields.name.label')"
							prepend-icon="mdi-account"
							dense
							clearable
						/>
						<v-text-field
							v-model="terms.company"
							:label="$i18n.t('fields.company.label')"
							prepend-icon="mdi-briefcase"
							dense
							clearable
						/>
						<v-autocomplete
							v-model="terms.academy"
							:label="$i18n.t('fields.academy.label')"
							:items="options.c_degree_institute_choose"
							item-text="title"
							item-value="id"
							prepend-icon="mdi-school"
							dense
							clearable
						/>
						<v-autocomplete
							v-model="terms.membershipType"
							:label="$i18n.t('fields.membershipType.label')"
							:items="options.membership_type"
							item-text="title"
							item-value="id"
							prepend-icon="mdi-account-star"
							dense
							clearable
						/>
					</v-form>
				</v-card-text>
			</v-card>
			<div class="my-6">
				<Spinner v-if="workerLoading === true" />
				<FilterableList
					v-else
					:items="itemsVisible"
					:enable-search="false"
					title-src="title"
					icon-src="_list_icon"
					avatar-src="_list_avatar"
					:no-items-text="$i18n.t('form.noSearchResults')"
					:no-results-text="$i18n.t('form.noSearchResults')"
					@itemClick="onItemClick"
				>
					<template #avatar="{item}">
						<v-img
							alt=""
							:src="item._list_avatar"
						/>
					</template>
				</FilterableList>
			</div>

			<!-- Scroll to top button -->
			<v-fab-transition>
				<v-btn
					v-show="showScrollTopButton === true && memberDialogOpen === false"
					fab
					dark
					right
					fixed
					color="primary"
					class="scrollTopButton"
					@click="scrollTop"
				>
					<v-icon>mdi-arrow-up</v-icon>
				</v-btn>
			</v-fab-transition>

			<!-- Details dialog -->
			<v-dialog
				v-model="memberDialogOpen"
				max-width="400"
				scrollable
			>
				<v-card v-if="selectedMember">
					<v-card-title>
						<span>{{ selectedMember.title }}</span>
						<v-spacer />
						<v-btn
							icon
							@click="memberDialogOpen = false"
						>
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-card-title>
					<v-card-text
						ref="modalText"
						class="pa-0"
					>
						<v-img
							v-if="selectedMember._profile_pic_urls && selectedMember._profile_pic_urls['500x600']"
							:src="selectedMember._profile_pic_urls['500x600']"
							aspect-ratio="0.8"
						/>
						<FilterableList
							v-if="selectedMember._details"
							class="pa-3"
							:items="selectedMember._details"
							subtitle-src="label"
							title-src="value"
							icon-src="icon"
							:enable-click="(false)"
							:enable-search="(false)"
							:multiline="true"
						/>
					</v-card-text>
				</v-card>
			</v-dialog>
		</template>
	</v-container>
</template>

<script>

import { baseUrl } from '@/plugins/api'

export default {
	name: 'MemberCatalog',
	data: () => ({
		baseUrl: baseUrl, // Backend base url
		ready: false, // Ready to be rendered?
		workerLoading: true, // Is web worker loading?
		options: {}, // Options from the register
		items: [], // All items
		itemsFiltered: [], // Filtered results, split in chunks/pages
		itemsVisible: [], // Items to be rendered
		terms: {
			name: null,
			company: null,
			academy: null,
		}, // Search terms
		pageSize: 50, // How many results per page?
		nextPage: null, // Next page number
		scrollTicking: false, // Is the scroll ticking?
		termsDebounceTimeout: null, // Timeout used to debounce terms change
		memberDialogOpen: false, // Is member dialog open?
		selectedMember: null,
		showScrollTopButton: false,
	}),
	computed: {
		placeholderSrc () {
			return require('@/assets/person-placeholder.jpg')
		},
	},
	watch: {
		// When items are changed, filter them
		items: {
			deep: true,
			handler (val) {
				this.filterItems(val, this.terms, this.pageSize)
			},
		},

		// When search terms are changed, filter items
		terms: {
			deep: true,
			handler (val) {
				if (this.ready === false) return

				clearTimeout(this.termsDebounceTimeout)

				if (val.name && val.name.length) {
					console.log(this.normalizeStr(val.name))
				}

				this.termsDebounceTimeout = setTimeout(() => {
					this.filterItems(this.items, {
						...val,
						name: this.normalizeStr(val.name),
					}, this.pageSize)
				}, 300)
			},
		},

		itemsFiltered: {
			deep: true,
			handler () {
				this.itemsVisible = []
				this.nextPage = 0
				this.insertNextPage()
			},
		},
	},
	async mounted () {
		this.ready = false

		const request = await this.$api.MemberCatalog.doRequest()

		if (request.success === true && request.result) {
			this.items = request.result.body.items || []
			this.options = request.result.body.options || {}

			this.$nextTick(async () => {
				this.ready = true

				// Add event listener for scrolling
				window.addEventListener('scroll', this.onScroll)

				// Depending on pagination chunk size, we might already reached the bottom of the page,
				// so we evaluate scroll just in case.
				this.onScroll()
			})

		}
	},

	beforeDestroy () {
		window.removeEventListener('scroll', this.onScroll)
	},

	methods: {
		normalizeStr (str = '') {
			if (!str || !str.length) return ''

			return str.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '')
		},

		// Filter items and split to chunks
		filterItems (items = [], terms = {}, pageSize) {
			this.workerLoading = true

			this.$worker.run(function (items, terms, pageSize) {
				if (!items.length) return []

				// We need to duplicate this function here, since worker has no access outside of it's scope.
				function normalizeStr (str) {
					if (!str || !str.length) return ''

					return str.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '')
				}

				let results

				if (terms && Object.keys(terms).length) {
					let nameRegex = new RegExp(terms.name, 'i')
					let companyRegex = new RegExp(terms.company, 'i')

					results = items.filter(item => {
						if (
							terms.name &&
							terms.name.length &&
							(
								!nameRegex.test(normalizeStr(item.title)) &&
								!nameRegex.test(normalizeStr([item.firstname, item.lastname].join(' ')))
							)
						) return false
						if (terms.company && terms.company.length && !companyRegex.test(item.c_company)) return false
						if (terms.academy && item.c_degree_institute_choose != terms.academy) return false
						if (terms.membershipType && item.membership_type != terms.membershipType) return false

						return true
					})
				} else {
					results = items
				}

				// Split into pages/chunks
				return Array.from({ length: Math.ceil(results.length / pageSize) }, (v, i) =>
					results.slice(i * pageSize, i * pageSize + pageSize)
				)
			}, [items, terms, pageSize]).then(result => {
				this.itemsFiltered = result
			}).catch(error => {
				console.log(error)

				this.itemsFiltered = []
			}).then(() => {
				this.workerLoading = false
			})
		},

		insertNextPage () {
			const page = this.itemsFiltered[this.nextPage]

			if (typeof page == 'undefined') return

			this.itemsVisible = [
				...this.itemsVisible,
				...page,
			]

			if (typeof this.itemsFiltered[this.nextPage +1] != 'undefined') {
				this.nextPage++
			} else {
				this.nextPage = null
			}
		},

		// When viewport is scrolled
		onScroll () {
			if (
				this.scrollTicking === true ||
        !this.nextPage
			) return

			this.scrollTicking = true

			window.requestAnimationFrame(() => {
				// If scroll position is near the bottom of the page, insert next page
				if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight * 0.8)) {
					this.insertNextPage()
				}

				this.showScrollTopButton = (window.scrollY > 430)

				this.scrollTicking = false
			})
		},

		onItemClick (item) {
			this.selectedMember = item

			// Parse detail list selected member
			this.selectedMember._details = [
				{
					icon: 'mdi-calendar',
					label: this.$i18n.t('details.birthdate'),
					value: this.selectedMember.birthdate || null,
				},
				{
					icon: 'mdi-medal',
					label: this.$i18n.t('details.honoraryTitle'),
					value: (() => {
						if (this.selectedMember.c_work_status) {
							if (this.selectedMember.c_work_status == 13830) {
								return this.$i18n.t('workStatuses.student')
							} else if (this.selectedMember.c_work_status == 13829) {
								return this.$i18n.t('workStatuses.retired')
							}
						}

						return this.selectedMember.c_honorary_title
					})(),
				},
				{
					icon: 'mdi-school',
					label: this.$i18n.t('details.educationDetails'),
					value: (() => {
						let out = []

						if (this.selectedMember.c_degree_choose) {
							const degree = this.options.c_degree_choose.find(item => item.id == this.selectedMember.c_degree_choose)

							if (degree) {
								if (degree.name == 'muu-oppiarvo' && this.selectedMember.c_degree_1) {
									out.push(this.selectedMember.c_degree_1)
								} else {
									out.push(degree.title)
								}
							}
						}

						if (this.selectedMember.c_degree_institute_choose) {
							const institute = this.options.c_degree_institute_choose.find(item => item.id == this.selectedMember.c_degree_institute_choose)

							if (institute) {
								if (institute.name == 'muu-oppilaitos' && this.selectedMember.c_degree_1_institute) {
									out.push(this.selectedMember.c_degree_1_institute)
								} else {
									out.push(institute.title)
								}
							}
						}

						out.push(this.selectedMember.c_degree_additional)

						return out.filter(item => item).join('<br>')
					})(),
				},
				{
					icon: 'mdi-briefcase',
					label: this.$i18n.t('details.jobInformation'),
					value: (() => {
						let out = [
							this.selectedMember.c_company || null,
							this.selectedMember.c_company_street_address || null,
						]

						out.push([
							this.selectedMember.c_company_postal_code ? this.selectedMember.c_company_postal_code + ' ' : null,
							this.selectedMember.c_company_city,
							this.selectedMember.c_company_country ? ', ' + this.selectedMember.c_company_country.title : null,
						].filter(item => item).join(''))

						return out.filter(item => item).join('<br>')
					})(),
				},
				{
					icon: 'mdi-email',
					label: this.$i18n.t('details.emailAddress'),
					value: (() => {
						if (!this.selectedMember.email_address) return null

						const segments = Array.from(this.selectedMember.email_address.matchAll(/[a-z\d]+[\.\-_@]?/gi), m => m[0])

						return segments.reduce((acc, value) => {
							acc += '<span class="d-inline-block">' + value + '</span>'

							return acc
						}, '')
					})(),
				},
				{
					icon: 'mdi-cellphone',
					label: this.$i18n.t('details.mobile'),
					value: this.selectedMember.mobile ? '<span class="force-break">' + this.selectedMember.mobile + '</span>' : null,
				},
				{
					icon: 'mdi-cellphone',
					label: this.$i18n.t('details.workPhone'),
					value: this.selectedMember.c_work_phone ? '<span class="force-break">' + this.selectedMember.c_work_phone + '</span>' : null,
				},
				{
					icon: 'mdi-map-marker',
					label: this.$i18n.t('details.homeAddress'),
					value: (() => {
						let out = [
							this.selectedMember.street_address || null,
						]

						out.push([
							this.selectedMember.postal_code,
							this.selectedMember.city,
						].filter(item => item).join(' '))

						out.push(this.selectedMember.address_suffix)

						if (this.selectedMember.country) {
							const country = this.options.country.find(item => item.id == this.selectedMember.country)
							if (country) out.push(country.title)
						}

						return out.filter(item => item).join('<br>')
					})(),
				},
				{
					icon: 'mdi-calendar-check',
					label: this.$i18n.t('details.joinDate'),
					value: this.selectedMember.date_joined || null,
				},
				{
					icon: 'mdi-link',
					label: this.$i18n.t('details.profile'),
					value: this.selectedMember.c_profile ? '<a href="' + this.selectedMember.c_profile + '" target="_blank">' + this.$i18n.t('details.viewProfile') + '</a>' : null,
				},
			].filter(item => item.value)

			// Open dialog and scroll to top
			this.memberDialogOpen = true

			this.$nextTick(() => {
				this.$refs.modalText.scrollTop = 0
			})
		},

		scrollTop () {
			this.$vuetify.goTo(0)
		},
	},
}

</script>

<style lang="scss" scoped>

.scrollTopButton {
	bottom: 70px;
}

</style>

