export default {
	general: {
		save: 'Tallenna',
		cancel: 'Peruuta',
		yes: 'Kyllä',
		no: 'Ei',
		goBack: 'Palaa takaisin',
		changeCookieSettings: 'Muuta evästeasetuksiasi',
	},
	routes: {
		error404: {
			title: 'Sivua ei löytynyt',
		},
		membershipCard: {
			title: 'Oma sivu',
		},
		myAccount: {
			title: 'Omat tiedot',
			headline: 'Omat tiedot',
		},
		benefitContainer: {
			title: 'Jäsenedut',
			headline: 'Jäsenedut',
		},
		benefit: {
			title: 'Jäsenetu',
			headline: 'Jäsenetu',
		},
		eventContainer: {
			title: 'Tapahtumat',
			headline: 'Tapahtumat',
		},
		event: {
			title: 'Tapahtuma',
			headline: 'Tapahtuma',
		},
		newsContainer: {
			title: 'Uutiset',
			headline: 'Uutiset',
		},
		news: {
			title: 'Uutinen',
			headline: 'Uutinen',
		},
		pageContainer: {
			title: 'Materiaalit',
		},
		page: {
			title: 'Sivu',
			headline: 'Sivu',
		},
		magazineContainer: {
			title: 'Lehti',
		},
		invoiceContainer: {
			title: 'Laskut',
			headline: 'Laskut',
		},
		invoice: {
			title: 'Lasku',
			headline: 'Lasku',
		},
		birthdays: {
			title: 'Merkkipäivät',
			headline: 'Merkkipäivät',
		},
		graduates: {
			title: 'Valmistuneet',
			headline: 'Valmistuneet',
		},
		memberCatalog: {
			title: 'Matrikkeli',
			headline: 'Matrikkeli',
		},
		settings: {
			title: 'Asetukset',
			headline: 'Asetukset',
		},
	},
	cookieTypes: {
		necessary: 'Välttämättömät',
		statistics: 'Tilastointi',
		marketing: 'Markkinointi',
	},
}
