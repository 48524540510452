<i18n>
{
  "en": {
		"headings": {
			"links": "Links",
			"application": "Application",
			"members": "Members"
		},
		"actions": {
			"logout": "Log out"
		}
	},
	"fi": {
		"headings": {
			"links": "Linkit",
			"application": "Sovellus",
			"members": "Jäsenet"
		},
		"actions": {
			"logout": "Kirjaudu ulos"
		}
	}
}
</i18n>

<template>
	<v-list
		dense
		nav
	>
		<!-- Link groups -->
		<template v-if="config.links.length">
			<v-list-item-group
				v-for="group in config.links"
				:key="group.id"
				color="primary"
			>
				<v-subheader class="text-uppercase">
					{{ group.title }}
				</v-subheader>

				<template v-if="group.hide_title === false">
					<v-list-item
						v-for="item in group.items"
						:key="item.id"
						:href="item.url"
						target="_blank"
						@click="onLinkClick"
					>
						<v-list-item-icon v-if="item.icon">
							<v-icon>
								{{ item.icon }}
							</v-icon>
						</v-list-item-icon>
						<v-list-item-content v-if="item.label">
							{{ item.label }}
						</v-list-item-content>
					</v-list-item>
				</template>
				<div
					v-else
					class="my-2"
				>
					<v-btn
						v-for="item in group.items"
						:key="item.id"
						:href="item.url"
						target="_blank"
						icon
						large
						class="mr-2"
					>
						<v-icon>
							{{ item.icon }}
						</v-icon>
					</v-btn>
				</div>
			</v-list-item-group>
		</template>

		<v-list-item-group
			color="primary"
			v-if="user._show_new_member_lists === true"
		>
			<v-subheader
				class="text-uppercase"
			>
				{{ $i18n.t('headings.members') }}
			</v-subheader>
			<v-list-item
				v-if="$router.options.routes.find(route => route.name == 'memberCatalog')"
				:to="({ name: 'memberCatalog' })"
			>
				<v-list-item-icon>
					<v-icon>
						mdi-account-multiple
					</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					{{ $i18n.t('routes.memberCatalog.title') }}
				</v-list-item-content>
			</v-list-item>
			<v-list-item
				v-if="$router.options.routes.find(route => route.name == 'birthdays')"
				:to="({ name: 'birthdays' })"
			>
				<v-list-item-icon>
					<v-icon>
						mdi-cake
					</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					{{ $i18n.t('routes.birthdays.title') }}
				</v-list-item-content>
			</v-list-item>
			<v-list-item
				v-if="$router.options.routes.find(route => route.name == 'graduates')"
				:to="({ name: 'graduates' })"
			>
				<v-list-item-icon>
					<v-icon>
						mdi-school
					</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					{{ $i18n.t('routes.graduates.title') }}
				</v-list-item-content>
			</v-list-item>
		</v-list-item-group>

		<v-list-item-group color="primary">
			<v-subheader
				class="text-uppercase"
			>
				{{ $i18n.t('headings.application') }}
			</v-subheader>
			<v-list-item
				href="https://asiointi.puunjalostusinsinoorit.fi"
				target="_blank"
			>
				<v-list-item-icon>
					<v-icon>
						mdi-account
					</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					{{ $i18n.t('routes.myAccount.title') }}
				</v-list-item-content>
			</v-list-item>
			<v-list-item
				v-if="$router.options.routes.find(route => route.name == 'invoiceContainer')"
				:to="({ name: 'invoiceContainer' })"
			>
				<v-list-item-icon>
					<v-icon>
						mdi-receipt
					</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					{{ $i18n.t('routes.invoiceContainer.title') }}
				</v-list-item-content>
			</v-list-item>
			<v-list-item
				v-if="$router.options.routes.find(route => route.name == 'settings')"
				:to="({ name: 'settings' })"
			>
				<v-list-item-icon>
					<v-icon>
						mdi-cog
					</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					{{ $i18n.t('routes.settings.title') }}
				</v-list-item-content>
			</v-list-item>
			<v-list-item @click="$emit('logout')">
				<v-list-item-icon>
					<v-icon>
						mdi-logout
					</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					{{ $i18n.t('actions.logout') }}
				</v-list-item-content>
			</v-list-item>
		</v-list-item-group>
	</v-list>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'OffCanvasNav',
	computed: {
		...mapState([
			'config',
			'user',
		]),
	},
	methods: {
		onLinkClick (item) {
			this.$nextTick(() => {
				setTimeout(() => {
					const link = item.target.closest('.v-list-item')

					link.classList.remove('v-list-item--active')
					link.classList.remove('v-item--active')
				}, 300)
			})
		},
	},
}

</script>

<style lang="scss" scoped>

</style>
