<i18n>
{
	"en": {
		"buttons": {
			"editYourDetails": "Edit your details"
		},
		"openInvoices": {
			"title": "Open invoices",
			"showAllInvoices": "Show all invoices"
		},
		"latestNews": {
			"title": "Latest news",
			"showAllNews": "Show all news"
		},
		"upcomingEvents": {
			"title": "Upcoming events",
			"showAllEvents": "Show all events"
		},
		"magazine": {
			"title": "Latest member magazine",
			"showAllMagazines": "Show all magazines"
		},
		"congratulations": {
			"title": "Congratulations!",
			"showAllBirthdays": "Upcoming birthdays",
			"showAllGraduates": "Recent graduates"
		}
	},
	"fi": {
		"buttons": {
			"editYourDetails": "Muokkaa tietojasi"
		},
		"openInvoices": {
			"title": "Avoimet laskut",
			"showAllInvoices": "Näytä kaikki laskut"
		},
		"latestNews": {
			"title": "Uutiset",
			"showAllNews": "Näytä kaikki uutiset"
		},
		"upcomingEvents": {
			"title": "Tulevat tapahtumat",
			"showAllEvents": "Näytä kaikki tapahtumat"
		},
		"magazine": {
			"title": "Viimeisin jäsenlehti",
			"showAllMagazines": "Näytä kaikki jäsenlehdet"
		},
		"congratulations": {
			"title": "Onnittelemme!",
			"showAllBirthdays": "Merkkipäiviään viettävät",
			"showAllGraduates": "Viimeisimmät valmistuneet"
		}
	}
}
</i18n>

<template>
	<div>
		<!-- Membership card -->
		<v-container class="container--narrow">
			<v-card>
				<v-img
					v-if="config._hero_image"
					class="membershipCard white--text align-end"
					aspect-ratio="1.628"
					:src="config._hero_image.url"
					gradient="rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .6) 100%"
					dark
				>
					<v-avatar
						v-if="user.profile_pic_thumb"
						class="membershipCard__avatar ml-4"
						size="80"
					>
						<v-img
							width="100"
							height="100"
							contain
							:src="user.profile_pic_thumb"
						/>
					</v-avatar>
					<v-card-title class="headline">
						{{ user.firstname }} {{ user.lastname }}
					</v-card-title>
					<v-card-subtitle
						class="white--text"
					>
						<span
							v-if="user._membership_type_title"
							class="d-block"
						>
							{{ user._membership_type_title }}
						</span>
						<span
							v-if="user.email_address"
							class="d-block"
						>
							{{ user.email_address }}
						</span>
						<span
							v-if="user.mobile"
							class="d-block"
						>
							{{ user.mobile }}
						</span>
					</v-card-subtitle>
				</v-img>
				<template v-else>
					<v-card-title class="headline">
						{{ user.firstname }} {{ user.lastname }}
					</v-card-title>
					<v-card-subtitle v-if="user._membership_type_title && user._membership_type_title.length">
						{{ user._membership_type_title }}
					</v-card-subtitle>
				</template>
				<v-card-actions>
					<v-btn
						:href="$i18n.locale == 'fi' ? 'https://asiointi.puunjalostusinsinoorit.fi/language/fi' : 'https://asiointi.puunjalostusinsinoorit.fi/language/en'"
						target="_blank"
						color="primary"
						text
					>
						<v-icon left>
							mdi-pencil
						</v-icon>
						{{ $i18n.t('buttons.editYourDetails') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-container>

		<!-- Open invoices -->
		<v-container
			v-if="user._open_invoices && user._open_invoices.length"
			class="container--narrow"
		>
			<v-card>
				<v-card-title>
					{{ $i18n.t('openInvoices.title') }}
				</v-card-title>
				<v-divider />
				<v-card-text>
					<InvoiceList :items="user._open_invoices" />
				</v-card-text>
				<v-divider />
				<v-card-actions>
					<v-btn
						:to="{ name: 'invoiceContainer' }"
						color="primary"
						text
					>
						<v-icon left>
							mdi-receipt
						</v-icon>
						{{ $i18n.t('openInvoices.showAllInvoices') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-container>

		<!-- Upcoming events -->
		<v-container
			v-if="upcomingEvents && upcomingEvents.length"
			class="container--narrow"
		>
			<v-card>
				<v-card-title>
					{{ $i18n.t('upcomingEvents.title') }}
				</v-card-title>
				<v-divider />
				<v-card-text>
					<FilterableList
						:items="upcomingEvents"
						:enable-search="false"
						:multiline="true"
					/>
				</v-card-text>
				<v-divider />
				<v-card-actions>
					<v-btn
						:to="{ name: 'eventContainer' }"
						color="primary"
						text
					>
						<v-icon left>
							mdi-calendar
						</v-icon>
						{{ $i18n.t('upcomingEvents.showAllEvents') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-container>

		<!-- Latest news -->
		<v-container
			v-if="latestNews && latestNews.length"
			class="container--narrow"
		>
			<v-card>
				<v-card-title>
					{{ $i18n.t('latestNews.title') }}
				</v-card-title>
				<v-divider />
				<v-card-text>
					<FilterableList
						:items="latestNews"
						subtitle-src="_date"
						redirect-url-src="link"
						:meta-src="null"
						:summary-src="null"
						:enable-search="false"
						:multiline="true"
					/>
				</v-card-text>
				<v-divider />
				<v-card-actions>
					<v-btn
						:to="{ name: 'newsContainer' }"
						color="primary"
						text
					>
						<v-icon left>
							mdi-bullhorn
						</v-icon>
						{{ $i18n.t('latestNews.showAllNews') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-container>

		<!-- Congratulations (birthdays and graduations) -->
		<v-container
			v-if="user._show_new_member_lists === true"
			class="container--narrow"
		>
			<v-card>
				<v-card-title>
					{{ $i18n.t('congratulations.title') }}
				</v-card-title>
				<v-divider />
				<v-card-text>
					<v-btn
						text
						block
						class="mb-4 justify-start"
						color="primary"
						:to="({ name: 'birthdays' })"
					>
						<v-icon left>
							mdi-cake
						</v-icon>
						{{ $t('congratulations.showAllBirthdays') }}
					</v-btn>
					<v-btn
						text
						block
						class="justify-start"
						color="primary"
						:to="({ name: 'graduates' })"
					>
						<v-icon left>
							mdi-school
						</v-icon>
						{{ $t('congratulations.showAllGraduates') }}
					</v-btn>
				</v-card-text>
			</v-card>
		</v-container>

		<!-- Latest member magazine -->
		<v-container
			v-if="magazines && magazines.items && magazines.items.length"
			class="latestMagazine container--narrow"
		>
			<v-card>
				<v-card-title>
					{{ $i18n.t('magazine.title') }}
				</v-card-title>
				<v-divider />
				<v-card-text>
					<a
						class="latestMagazine__link"
						:href="magazines.items[0].file_pdf.url"
						target="_blank"
					>
						<v-row>
							<v-col
								cols="4"
								sm="2"
							>
								<v-img
									v-if="magazines.items[0].image"
									:src="magazines.items[0].image.url"
									aspect-ratio="0.8"
								/>
							</v-col>
							<v-col
								cols="8"
								sm="10"
								align-self="center"
							>
								<span class="font-weight-bold">
									{{ magazines.items[0].title }}
								</span>
							</v-col>
						</v-row>
					</a>
				</v-card-text>
				<v-divider />
				<v-card-actions>
					<v-btn
						:to="{ name: 'magazineContainer' }"
						color="primary"
						text
					>
						<v-icon left>
							mdi-newspaper-variant
						</v-icon>
						{{ $i18n.t('magazine.showAllMagazines') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-container>
	</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'MembershipCard',
	computed: {
		...mapState([
			'config',
			'user',
			'news',
			'events',
			'magazines',
		]),
		latestNews () {
			if (!this.news || !this.news.length) return []

			return this.news.slice(0, 3)
		},
		upcomingEvents () {
			if (!this.events || !this.events.length) return []

			return this.events.slice(0, 2)
		},
	},
	mounted () {
		this.$api.Me.doRequest()
		this.$api.Magazines.doRequest()
	},
}
</script>

<style lang="scss" scoped>

.membershipCard {
	.v-card__title {
		@media (max-width: 400px) {
			padding-top: 8px !important;
			font-size: 1em !important;
		}
	}

	.v-card__subtitle {
		@media (max-width: 400px) {
			font-size: .75em !important;
			line-height: 1.375 !important;
		}
	}

	&__avatar {
		@media (max-width: 400px) {
			width: 60px !important;
			min-width: 60px !important;
			height: 60px !important;
			min-height: 60px !important;
		}
	}
}

.latestMagazine {
	&__link {
		text-decoration: none;
	}
}

</style>
